<template>
	<div
		class="row CookiePolicy align-items-center justify-content-center"
		:class="{ 'is-visible': isVisible }">
		<div class="col-16 col-md-6 col-lg-4 Text-center">
			<img
				class="CookiePolicy--image"
				src="./assets/cookie-policy.png?url" />

			<p class="CookiePolicy--text">
				{{ language.string.cCookiePolicy?.text }}

				<button
					Class="Btn"
					@click="acceptCookiePolicy">
					{{ language.string.cCookiePolicy?.button }}
				</button>
			</p>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'CookiePolicy',

		//  ---------- SETUP ----------
		setup() {
			const language = useLanguageStore();
			const isVisible = Core.Vue.ref(false);
			const router = Core.VueRouter.useRouter();

			Core.Vue.onMounted(() => {
				const cookiePolicy = localStorage.getItem('cookiePolicy');

				setTimeout(() => {
					if (
						!cookiePolicy &&
						router.currentRoute.value.path !== '/404' &&
						router.currentRoute.value.path !== '/error'
					) {
						isVisible.value = true;
					}
				}, 1000);
			});

			/**
			 *
			 */
			function acceptCookiePolicy() {
				localStorage.setItem('cookiePolicy', 'accepted');
				isVisible.value = false;
			}

			return { language, isVisible, acceptCookiePolicy };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('CookiePolicy') {
		z-index: 1000;
		width: 100%;
		height: 100vh;
		position: fixed;
		top: -100vh;
		opacity: 0;
		transition: opacity 0.5s;

		&.is-visible {
			opacity: 1;
			top: 0;
		}

		@include element('image') {
			border-radius: 50%;
			box-shadow: 0 0 200px 200px var(--color-greyStart);
			padding: 0;
		}

		@include element('text') {
			text-align: center;
			background-color: var(--color-greyStart);
			padding: var(--space-single);
			display: block;
			box-shadow: 0 0 160px 200px var(--color-greyStart);
		}
	}
</style>
