<template>
	<div
		class="BpForms BpUpload"
		:data-testid="id + '-upload'"
		:class="currentClasses">
		<p
			v-if="label"
			class="BpForms--label">
			{{ label }} <span class="BpForms--optional">{{ language.string.cForms.optional }}</span>
			<span
				v-if="tooltip"
				class="Tooltip Tooltip-bottom"
				:data-tooltip="tooltip"
				><span class="Tooltip-icon BpForms--tooltip"></span
			></span>
		</p>

		<label :for="id">
			<div
				class="BpUpload--dragdrop"
				@dragover="onDragover"
				@dragleave="onDragleave"
				@drop="onDrop">
				<div class="row Space-bottom Space-top">
					<div
						v-if="!smallVersion"
						class="col-24 align-self-center Text-center">
						<div class="iconFont-upload BpUpload--icon Color-grey600"></div>
					</div>

					<div
						class="col-24 Text-center"
						:class="!smallVersion && 'Space-topDouble Space-bottom'">
						<p class="Text-large Space-bottom">
							{{ language.string.cForms.uploadText }}
						</p>
						<span
							v-if="!smallVersion || (smallVersion && !filelist.length)"
							class="BpUpload--reqs">
							{{ language.string.cForms.uploadSize }}
							{{ Core.Utils.humanFileSize(maxSize) }},
						</span>

						<span
							v-if="!smallVersion || (smallVersion && !filelist.length)"
							class="BpUpload--reqs">
							{{ language.string.cForms.uploadFormat
							}}{{ fileTypes ? fileTypes.replaceAll(',', ' ') : 'any' }}
						</span>
					</div>

					<input
						:id="id"
						ref="fileField"
						class="BpUpload--input"
						data-testid="upload"
						:disabled="disabled"
						type="file"
						multiple
						:name="id + '[assetsFieldHandle][]'"
						:accept="fileTypes"
						@change="onChange" />

					<ul
						v-if="filelist.length"
						class="col-lg-24 BpUpload--filelist">
						<transition-group name="slide-down">
							<li
								v-for="(file, index) in filelist"
								:key="index"
								class="row align-items-center">
								<div class="col-16 col-sm-12 BpUpload--filename">
									<span class="iconFont-attachement BpUpload--uploadIcon"></span>
									<span>{{ file.name }}</span>
								</div>
								<div
									v-if="!uploadProgress[file.name]"
									class="col-8 col-sm-12">
									<div
										class="BpUpload--trashIcon iconFont-delete"
										data-testid="delete-file"
										type="button"
										:title="language.string.cForms.uploadRemove"
										@click.prevent="onRemove(filelist.indexOf(file))"></div>
									<span class="BpUpload--fileSize">
										{{ Core.Utils.humanFileSize(file.size) }}</span
									>
								</div>
								<div
									v-else-if="uploadProgress[file.name] === 'error'"
									class="col-8 col-sm-12 Text-right">
									<div class="Color-danger"><b>Upload error, try again</b></div>
								</div>
								<blueprint-progress-line
									v-else
									:progress="uploadProgress[file.name]"
									class="col-8 col-sm-12 align-self-center BpUpload--progress" />
							</li>
						</transition-group>
					</ul>
				</div>
			</div>
		</label>

		<transition name="slide-down">
			<div
				v-if="currentMsg"
				class="BpForms--msg">
				{{ currentMsg }}
			</div>
		</transition>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import * as Helpers from './helpers.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'BlueprintUpload',

		// ---------- PROPS ----------
		props: {
			/**
			 * property {string} id - unique id
			 * @namespace  Core_Blueprint_Upload
			 * @property {string} id - unique id
			 */
			id: {
				type: String,
				required: true
			},

			/**
			 * property {string} [label] - label, or title
			 * @namespace Core_Blueprint_Upload
			 * @property {string} [label] - label, or title
			 */
			label: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {number} [maxSize=5242880] - max file size in bytes
			 * @namespace Core_Blueprint_Upload
			 * @property {number} [maxSize=5242880] - max file size in bytes
			 */
			maxSize: {
				type: Number,
				required: false,
				default: 5242880
			},

			/**
			 * property {number} [maxFiles=1] - max number of file uploads
			 * @namespace Core_Blueprint_Upload
			 * @property {number} [maxFiles=1] - max number of file uploads
			 */
			maxFiles: {
				type: Number,
				required: false,
				default: 1
			},

			/**
			 * property {number} [minFiles=1] - min number of file uploads
			 * @namespace Core_Blueprint_Upload
			 * @property {number} [minFiles=1] - min number of file uploads
			 */
			minFiles: {
				type: Number,
				required: false,
				default: 1
			},

			/**
			 * property {Array} [value] - bind your value
			 * @namespace Core_Blueprint_Upload
			 * @property {Array} [value] - bind your value
			 */
			value: {
				type: Array,
				required: false,
				default: null
			},

			/**
			 * property {boolean} [disabled=false] - should this field be "disabled"
			 * @namespace Core_Blueprint_Upload
			 * @property {boolean} [disabled=false] - should this field be "disabled"
			 */
			disabled: {
				type: Boolean,
				required: false
			},

			/**
			 * property {string} [fileTypes='.pdf,.jpg,.jpeg,.png'] - define allowed file types of input. Comma seperated with dot.
			 * @namespace Core_Blueprint_Upload
			 * @property {string} [fileTypes='.pdf,.jpg,.jpeg,.png'] - define allowed file types of input. Comma seperated with dot.
			 */
			fileTypes: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [msg] - message to display below the field
			 * @namespace Core_Blueprint_Upload
			 * @property {string} [msg] - message to display below the field
			 */
			msg: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [msgType=notification] - type of the message
			 * @namespace Core_Blueprint_Upload
			 * @property {string} [msgType=notification] - type of the message
			 */
			msgType: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {string} [required=false] - is this field required?
			 * @namespace Core_Blueprint_Upload
			 * @property {string} [required=false] - is this field required?
			 */
			required: {
				type: Boolean,
				required: false
			},

			/**
			 * property {string} [tooltip] - content to be shown as tooltip within label area
			 * @namespace Core_Blueprint_Upload
			 * @property {string} [tooltip] - content to be shown as tooltip within label area
			 */
			tooltip: {
				type: String,
				required: false,
				default: null
			},

			/**
			 * property {boolean} [showValidationText=true] - do we want validation text to be shown
			 * @namespace Core_Blueprint_Upload
			 * @property {boolean} [showValidationText=true] - do we want validation text to be shown
			 */
			showValidationText: {
				type: Boolean,
				required: false,
				default: true
			},

			/**
			 * property {Function} [dropUploadMethod=noop] - function to fire to start the upload after drapg&drop
			 * @namespace Core_Blueprint_Upload
			 * @property {Function} [dropUploadMethod=noop] - function to fire to start the upload after drapg&drop
			 */
			autoUploadMethod: {
				type: Function,
				required: false,
				default: function () {}
			},

			/**
			 * property {boolean} [smallVersion=false] - is this a small version of the field?
			 * @namespace Core_Blueprint_Upload
			 * @property {boolean} [smallVersion=false] - is this a small version of the field?
			 */
			smallVersion: {
				type: Boolean,
				required: false,
				default: false
			}
		},

		//  ---------- EMITS ----------
		emits: ['update:value'],

		// ---------- SETUP ----------
		setup(props, context) {
			// Delay used in post request to s3 as they have limit of request in period of time
			const THROTTLE_DELAY = 500;

			const language = useLanguageStore();
			const currentMsg = Core.Vue.ref(props.msg);
			const currentMsgType = Core.Vue.ref(props.msgType || 'notification');
			const allowedFormats = props.fileTypes ? props.fileTypes.split(',') : null;
			const uploading = Core.Vue.ref(false);

			const filelist = Core.Vue.ref([]);
			const fileField = Core.Vue.ref();

			const currentClasses = Core.Vue.computed(
				() =>
					`${props.smallVersion ? 'BpUpload-smallVersion' : ''} ${props.disabled ? 'is-disabled' : ''} ${props.required ? 'is-required' : ''} ${currentMsgType.value ? `is-${currentMsgType.value}` : ''}`
			);

			// reactive props
			const state = Core.Vue.computed(() => {
				return {
					...props // make all props reactive
				};
			});

			// ---------- HANDLE EVENTS ----------
			/**
			 * on input field change (added files etc)
			 */
			function onChange() {
				filelist.value = [...fileField.value.files];
				validate();
				props.autoUploadMethod(filelist.value);
			}

			/**
			 * on removal of selected file, remove from the data object
			 * @param {number} i index of item
			 */
			function onRemove(i) {
				filelist.value.splice(i, 1);
				fileField.value.value = '';
				validate();
			}

			/**
			 * When dragged file is over the field
			 * @param {object} event object after dragging
			 */
			function onDragover(event) {
				if (!props.disabled) {
					event.preventDefault();
					event.currentTarget.classList.add('is-hovered');
				}
			}

			/**
			 * clean up the field after drag finished or left
			 * @param {object} event object when drag leaves area
			 */
			function onDragleave(event) {
				event.currentTarget.classList.remove('is-hovered');
			}

			/**
			 * when file dropped, add it to the existing file list for processing
			 * @param {object} event object on drop into box
			 */
			function onDrop(event) {
				if (!props.disabled) {
					event.preventDefault();
					fileField.value.files = event.dataTransfer.files;
					this.onChange(); // Trigger the onChange event manually
					onDragleave(event); // Clean up
				}
			}

			// -------------- VALIDATION  --------------
			const validated = Core.Vue.ref(true);

			/**
			 * validate() validation function to check the input on string if it's aligned with the requirements.
			 * @namespace Core_Blueprint_Upload
			 * @returns {boolean} validation result
			 */
			function validate() {
				validated.value = true;

				// loop through attached files
				for (let i = 0; i < filelist.value.length; i++) {
					// check file extension
					let extensionOk = false;

					const splitName = filelist.value[i].name.split('.');
					if (allowedFormats === null) {
						extensionOk = true;
					}
					for (const item in allowedFormats) {
						if (
							allowedFormats[item] ===
							`.${splitName[splitName.length - 1].toLowerCase()}`
						) {
							extensionOk = true;
						}
					}
					if (!extensionOk) {
						showMsg('error', language.string.cForms.uploadExtension);
					}

					// check file size
					if (filelist.value[i].size > props.maxSize) {
						showMsg('error', language.string.cForms.uploadTooBig);
					}
				}

				// if required & below
				if (validated.value && props.required && filelist.value.length < props.minFiles) {
					showMsg('error', language.string.cForms.uploadNotEnough);
				} else if (validated.value && filelist.value.length > props.maxFiles) {
					// check max number of files
					showMsg('error', language.string.cForms.uploadTooMany);
				} else if (validated.value) {
					showMsg();
				}

				return validated.value;
			}

			/**
			 * it will populate the correct values to make sure message is visibile
			 * if vars not provied, clean the messages and validate as all ok
			 * @param {string} [type=null] of error
			 * @param {string} [content=null] of the error
			 */
			function showMsg(type = null, content = null) {
				currentMsgType.value = type;
				currentMsg.value = content;

				if (type === 'error') {
					validated.value = false;
				}
			}

			// -------------- UPLOAD FUNCTIONALITY  --------------
			const uploadProgress = Core.Vue.reactive({});
			let output = [];
			let uploadPromise;
			/**
			 * Promise upload() will check validation one more time and upload to S3 all files defined in this component
			 * Once all is done, you will get an resolve with file upload data, or reject (only if validation failed).
			 * @namespace Core_Blueprint_Upload
			 * @param {string} url - generate-upload-url api
			 * @param {object} data - data to be sent with the file
			 * @returns {Promise} array of files (our own file object). It will also emit event to populate prop.value
			 */
			function upload(url, data) {
				// if initiated twice for some reason, for the same files, then return the same promise
				if (uploadPromise) {
					return uploadPromise;
				}

				uploading.value = true;

				const tempPromiseObject = new Promise((resolve, reject) => {
					if (validate() && filelist.value.length > 0) {
						let fileCount = 0;

						// filter file list to new files or errored
						const eligibleFiles = [];
						for (const item in filelist.value) {
							if (
								!uploadProgress[filelist.value[item].name] ||
								uploadProgress[filelist.value[item].name] === 'error'
							) {
								eligibleFiles.push(filelist.value[item]);
							}
						}

						// loop through all selected files
						for (const item in eligibleFiles) {
							fileCount++;

							const formData = new FormData();
							const currentFile = eligibleFiles[item];
							uploadProgress[currentFile.name] = 1; // reset progress for given file

							// append the file
							formData.append('file', currentFile);
							// append data
							for (const key in data) {
								formData.append(key, data[key]);
							}

							const xhr = new XMLHttpRequest();
							xhr.withCredentials = true;

							// progress tracking
							xhr.upload.onprogress = function (event) {
								uploadProgress[currentFile.name] =
									(event.loaded / event.total) * 100;
							};

							// post the files
							// eslint-disable-next-line no-loop-func
							setTimeout(function () {
								xhr.open('POST', url, true);

								xhr.send(formData);

								xhr.onload = function () {
									//if all ok...
									if (this.status === 200) {
										// ALL OK, FILE UPLOADED
										uploadProgress[currentFile.name] = 100;

										fileCount--;

										output.push({
											name: JSON.parse(this.responseText).message[
												currentFile.name
											],
											size: currentFile.size,
											time: new Date().toISOString(),
											location: currentFile.name,
											id: Core.Utils.makeUuid()
										});

										// if all files uploded, resolve this upload process
										if (fileCount === 0) {
											context.emit('update:value', output);
											resolve(output);
											uploadPromise = tempPromiseObject;
											output = [];
										}
									} else {
										// something went wrong :/
										triggerError(this);
										reject(this.responseText);
										resetStatus();
									}
								};
							}, THROTTLE_DELAY * fileCount);
						}
					} else {
						// not validated
						uploading.value = false;
						reject();
					}
				});

				return tempPromiseObject;
			}

			/**
			 * resets the component back to initial state
			 */
			function resetStatus() {
				// reset data
				filelist.value = [];

				for (const itemDel in uploadProgress) {
					delete uploadProgress[itemDel];
				}

				uploading.value = false;
			}

			/**
			 * Reset field to initial state
			 */
			function reset() {
				Helpers.resetAll({ currentMsgType, currentMsg }, state, { value: [], reset: true });
				filelist.value = [];
				//Let the component where its used to be aware of the reset value
				context.emit('update:value', state.value.value);

				fileField.value.value = '';
			}

			/**
			 * Trigger error when upload failed
			 * @param {object} extraMsg xhr response object
			 */
			function triggerError(extraMsg) {
				Core.Event.trigger('ERROR', {
					type: 'custom',
					title: language.string.cForms.uploadError.title,
					text: language.string.cForms.uploadError.desc,
					notes: `${extraMsg.status}: ${extraMsg.statusText}`,
					icon: 'iconFont-upload',
					btnOk: true,
					btnReport: true,
					log: `CORE.COMPONENT.UPLOAD ${extraMsg.status}: ${extraMsg.statusText}`
				});
			}

			return {
				Core,
				language,
				allowedFormats,
				currentClasses,
				validate,
				reset,
				upload,
				showMsg,
				uploading,
				uploadProgress,
				currentMsg,
				filelist,
				fileField,
				onChange,
				onRemove,
				onDragover,
				onDragleave,
				onDrop
			};
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpUpload') {
		@include modifier('smallVersion') {
			overflow: hidden;

			& .BpUpload--dragdrop {
				height: 165px;
			}
		}
		@include element('dragdrop') {
			background-color: #f6f6f6;
			border: 1px dashed var(--color-grey600);
			padding: var(--space-double);
			font-size: var(--text-small);
			border-radius: var(--radius-big);
			cursor: pointer;

			&.is-hovered,
			&:hover {
				border: 1px solid var(--color-greyEnd) !important;
				background-color: var(--color-stateInfoBg);
			}
		}

		@include element('select') {
			float: right;
		}

		@include element('input') {
			display: none;
		}

		@include element('icon') {
			font-size: 55px !important;
		}

		@include element('filelist') {
			margin-top: var(--space-single) !important;
			color: var(--color-grey500);
			margin-left: 0; // icon width
			list-style-type: none;
			white-space: nowrap;

			& li {
				height: 35px;
			}
		}
		@include element('filename') {
			text-overflow: ellipsis;
			overflow: hidden;
		}
		@include element('fileSize') {
			float: right;
			margin-right: var(--space-single);
			margin-top: 8px;
		}
		@include element('uploadIcon') {
			font-size: 20px !important;
			margin-right: 6px;
			display: inline-block;
			vertical-align: top;
		}
		@include element('trashIcon') {
			display: inline-block;
			float: right;
			margin-top: 10px;

			&:hover {
				color: var(--color-greyEnd);
			}
		}

		// style states
		&.is-error .BpUpload--dragdrop {
			border-color: var(--color-stateDanger);
			background-color: var(--color-stateDangerBg);
		}

		&.is-success .BpUpload--dragdrop {
			border-color: var(--color-stateSuccess);
			background-color: var(--color-stateSuccessBg);
		}

		&.is-warning .BpUpload--dragdrop {
			border-color: var(--color-stateWarning);
			background-color: var(--color-stateWarningBg);
		}

		&.is-disabled .BpUpload--dragdrop {
			background-color: var(--color-grey900);
			color: var(--color-grey600);
		}
	}
</style>
