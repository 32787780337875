import * as Core from '@Core/index.js';

// STORE DEFINITIONS -------------------
/**
 * @exports School_Store_School
 * @namespace School_Store_School
 */
export const useSchoolStore = Core.Pinia.defineStore({
	id: 'school',

	state: () => ({
		data: {
			school: {},
			stripe: {}
		},
		loaded: {
			school: false,
			stripe: false
		}
	}),

	actions: {
		/**
		 * Check if user is logged in, and get the details into store
		 * @async
		 * @param {boolean} force the check again, even it was done?
		 * @memberof School_Store_School
		 */
		async fetch(force) {
			if (!force && this.loaded.school) {
				return;
			}

			this.loaded.school = false;
			const response = await Core.Api.get('school/school');
			this.data.school = response.body.message;
			this.loaded.school = true;

			// only if school data loaded
			if (this.data.school) {
				this.stripeStatus(true);
			}
		},

		/**
		 * Update the school details (create account too)
		 * @memberof School_Store_School
		 * @async
		 * @param {object} data needed for the update/register
		 * @returns {Promise<boolean | object>} if the update was successful
		 */
		async updateDetails(data) {
			const finalData = {
				...data,
				id: this.data.school?.id || null
			};

			const response = await Core.Api.post('school/school-update', finalData);

			if (response.body.type === 'SUCCESS') {
				this.fetch(true);
			}

			return false;
		},

		/**
		 * Update the school branding
		 * @memberof School_Store_School
		 * @async
		 * @param {object} data needed for the update
		 * @returns {Promise<boolean>} if the update was successful
		 */
		async updateBranding(data) {
			const response = await Core.Api.post('school/school-branding-update', data);
			if (response.body.type === 'SUCCESS') {
				await this.fetch(true);
				return true;
			}
			return false;
		},

		/**
		 * Update the school notifications
		 * @memberof School_Store_School
		 * @async
		 * @param {object} data needed for the update
		 * @returns {Promise<boolean>} if the update was successful
		 */
		async updateNotifications(data) {
			const response = await Core.Api.post('school/school-notifications-update', data);
			if (response.body.type === 'SUCCESS') {
				await this.fetch(true);
				return true;
			}
			return false;
		},

		/**
		 * Update links across the school
		 * @memberof School_Store_School
		 * @async
		 * @param {object} data links array
		 * @param {string} type the type of links to update
		 * @returns {Promise<boolean>} if the update was successful
		 */
		async updateLinks(data, type) {
			const response = await Core.Api.post('school/school-links-update', { type, data });
			if (response.body.type === 'SUCCESS') {
				await this.fetch(true);
				return true;
			}
			return false;
		},

		/**
		 * Update the school bank details
		 * @memberof School_Store_School
		 * @async
		 * @param {object} data needed for the update
		 * @returns {Promise<boolean>} if the update was successful
		 */
		async saveBankDetails(data) {
			const response = await Core.Api.post('school/school-bank-update', data);
			if (response.body.type === 'SUCCESS') {
				await this.fetch(true);
				return true;
			}
			return false;
		},

		/**
		 * Update the school stripe status
		 * @memberof School_Store_School
		 * @param {boolean} force the check again, even it was done?
		 * @async
		 */
		async stripeStatus(force) {
			if (!force && this.loaded.school) {
				return;
			}

			const statusResponse = await Core.Api.post('school/payments-stripe-status');
			const stripeStatus = statusResponse.body.message;

			this.data.stripe.status = 'start';
			this.data.stripe.status = stripeStatus?.accountId
				? 'inProgress'
				: this.data.stripe.status;
			this.data.stripe.status = stripeStatus?.completed
				? 'completed'
				: this.data.stripe.status;
		}
	},

	getters: {
		/**
		 * Return details about current school
		 * @memberof School_Store_School
		 * @param {object} state automatically passed in
		 * @returns {object} data for design changes
		 */
		get: (state) => {
			return state.data.school || {};
		},

		/**
		 * Get stripe details object
		 * @memberof School_Store_School
		 * @param {object} state automatically passed in
		 * @returns {object} stripe data fetched from status
		 */
		getStripe: (state) => {
			return state.data.stripe;
		}
	}
});
