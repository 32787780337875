import * as Vue from 'vue';
import * as Pinia from 'pinia';
import * as VueRouter from 'vue-router';

import AppLog from './modules/AppLog/index.js';

import * as bpForms from './components/Blueprint/Forms/index.js';
import bpPopup from './components/Blueprint/Popup/index.vue';
import bpProgressCircle from './components/Blueprint/ProgressCircle/index.vue';
import bpProgressLine from './components/Blueprint/ProgressLine/index.vue';
import bpPill from './components/Blueprint/Pill/index.vue';
import bpAccordion from './components/Blueprint/Accordion/index.vue';
import bpLazy from './components/Lazy/index.vue';
import bpLoader from './components/Loader/index.vue';
import bpSectionProgress from './components/Blueprint/SectionProgress/index.vue';
import bpMemoryCallout from './components/Blueprint/MemoryCallout/index.vue';

// -------------------------------------- RUN ----------------------------------------------
let VueApp;
const Store = {};

/**
 * Initialisation function to prepare all the Core functionality, including creating vueJS app.
 * @param {object} theApp Provide your vue app object
 */
export function init(theApp) {
	// ADD GLOBAL APP LOG
	AppLog();

	// create base Vue App
	VueApp = theApp; // assign so we can use it

	// Create all global components for the app (Blueprint components only!)
	VueApp.component('BlueprintInput', bpForms.Input);
	VueApp.component('BlueprintTextarea', bpForms.Textarea);
	VueApp.component('BlueprintCheckbox', bpForms.Checkbox);
	VueApp.component('BlueprintToggle', bpForms.Toggle);
	VueApp.component('BlueprintFancyToggle', bpForms.FancyToggle);
	VueApp.component('BlueprintRadio', bpForms.Radio);
	VueApp.component('BlueprintSelect', bpForms.Select);
	VueApp.component('BlueprintHorizontalRadio', bpForms.HorizontalRadio);
	VueApp.component('BlueprintUpload', bpForms.Upload);
	VueApp.component('BlueprintAddress', bpForms.Address);
	VueApp.component('BlueprintWysiwyg', bpForms.Wysiwyg);
	VueApp.component('BlueprintPopup', bpPopup);
	VueApp.component('BlueprintProgressCircle', bpProgressCircle);
	VueApp.component('BlueprintProgressLine', bpProgressLine);
	VueApp.component('BlueprintPill', bpPill);
	VueApp.component('BlueprintAccordion', bpAccordion);
	VueApp.component('BlueprintLazy', bpLazy);
	VueApp.component('BlueprintLoader', bpLoader);
	VueApp.component('BlueprintSectionProgress', bpSectionProgress);
	VueApp.component('BlueprintMemoryCallout', bpMemoryCallout);

	app.log('APP', 'initialised');
}

// -------------------------------------- EXPORTS ------------------------------------------
export { VueApp };

// libraries
export { Vue };
export { Pinia };
export { VueRouter };
export { Store };

// components (vue) - PREFIXED with "c", always!
export { default as cErrorHandler } from './components/ErrorHandler/index.vue';
export { default as cNotifications } from './components/Notifications/index.vue';
export { default as cCookiePolicy } from './components/CookiePolicy/index.vue';
export { default as cBreadcrumbs } from './components/Breadcrumbs/index.vue';
export * as cDesign from './components/Design/index.js';

// modules
export { default as Config } from './config.js';
export * as Cookie from './modules/Cookie/index.js';
export * as Storage from './modules/Storage/index.js';
export * as Event from './modules/Event/index.js';
export * as Utils from './modules/Utils/index.js';
export * as Tracking from './modules/Tracking/index.js';
export * as Api from './modules/Api/index.js';
export * as StaticData from './modules/StaticData/index.js';
export * as Hash from './modules/Hash/index.js';
