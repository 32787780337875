import * as Core from '@Core/index.js';

const DEFAULT_STORE = {
	data: {
		products: []
	},
	loaded: {
		products: false
	}
};

// STORE DEFINITIONS -------------------
/**
 * @exports School_Store_Product
 * @namespace School_Store_Product
 */
export const useProductStore = Core.Pinia.defineStore({
	id: 'product',

	state: () => ({
		...Core.Utils.cloneObject(DEFAULT_STORE)
	}),

	actions: {
		/**
		 * Retrieve all products from the API for current school
		 * @memberof School_Store_Product
		 * @async
		 * @param {boolean} [force=false] force refresh
		 */
		async fetchProducts(force = false) {
			if (this.loaded.products && !force) return; // do nothing if already loaded

			const response = await Core.Api.get('school/products');
			const results = response.body.message || [];

			this.data.products = results;
			this.loaded.products = true;
		},

		/**
		 * Fetch all details and data for single product
		 * @memberof School_Store_Product
		 * @async
		 * @param {number} id of the page
		 * @param {boolean} [force] the download again
		 */
		async fetchDetails(id, force) {
			const productIndex = this.data.products.findIndex((product) => product.id === id);

			if (!productIndex.content || force) {
				const response = await Core.Api.get(`school/products-details/${id}`);

				if (response.body.type === 'SUCCESS') {
					response.body.message.price = response.body.message.price?.toFixed(2) || null; // format price to 0.00

					if (productIndex > -1) {
						this.data.products[productIndex] = response.body.message;
					} else {
						this.data.products.push(response.body.message);
					}
				}
			}
		},

		// ------------------------- ACTIONS FOR LESSONS -------------------------
		/**
		 * Unpublish any given product
		 * @memberof School_Store_Product
		 * @async
		 * @param {number} lessonId of the lesson
		 * @returns {Promise<boolean>} once completed
		 */
		async lessonUnpublish(lessonId) {
			const lesson = this.getLessonById(lessonId);

			if (lesson) {
				const response = await Core.Api.post('school/products-lesson-unpublish', {
					lessonId: lessonId
				});

				if (response.body.type === 'SUCCESS') {
					lesson.publishDate = null;
					Core.Event.trigger('NOTIFICATIONS', {
						type: 'success',
						content: `Your lesson has been removed form the public view and won\'t be listed on any page!`,
						icon: 'iconFont-tick'
					});
					return true;
				}
			}

			Core.Event.trigger('NOTIFICATIONS', {
				type: 'danger',
				content: `Sorry, we couldn't unpublish this lesson. Try again later.`,
				icon: 'iconFont-cross'
			});
			return false;
		},

		/**
		 * Publish existing lessons
		 * @memberof School_Store_Product
		 * @async
		 * @param {number} lessonId of the lesson
		 * @returns {Promise<boolean>} if successful
		 */
		async lessonPublish(lessonId) {
			const lesson = this.getLessonById(lessonId);

			if (lesson) {
				const response = await Core.Api.post('school/products-lesson-publish', {
					lessonId: lessonId
				});
				if (response.body.type === 'SUCCESS') {
					lesson.publishDate = Core.Utils.createDate();
					Core.Event.trigger('NOTIFICATIONS', {
						type: 'success',
						content: `Your lesson with all assignments is now published!`,
						icon: 'iconFont-tick'
					});
					return true;
				}
			}

			Core.Event.trigger('NOTIFICATIONS', {
				type: 'danger',
				content: `Can't publish lesson, please try again later.`,
				icon: 'iconFont-cross'
			});
			return false;
		},

		/**
		 * Delete existing lesson
		 * @memberof School_Store_Product
		 * @async
		 * @param {number} lessonId of the lesson
		 * @returns {Promise<boolean>} if successful
		 */
		async deleteLesson(lessonId) {
			const lesson = this.getLessonById(lessonId);

			if (lesson) {
				const response = await Core.Api.post('school/products-lesson-delete', {
					lessonId: lessonId
				});
				if (response.body.type === 'SUCCESS') {
					// remove lesson from the product
					const product = this.getProductById(lesson.productId);
					product.lessons = product.lessons.filter(
						(thisLesson) => thisLesson.id !== lessonId
					);

					Core.Event.trigger('NOTIFICATIONS', {
						type: 'success',
						content: `Your lesson has been deleted!`,
						icon: 'iconFont-tick'
					});
					return true;
				}
			}

			Core.Event.trigger('NOTIFICATIONS', {
				type: 'danger',
				content: `Sorry, we couldn't delete this lesson. Try again later.`,
				icon: 'iconFont-cross'
			});
			return false;
		},

		/**
		 * Save lesson data
		 * @memberof School_Store_Product
		 * @async
		 * @param {object} data to save
		 * @returns {Promise<boolean>} if successful
		 */
		async saveLesson(data) {
			const finalData = {
				id: data.id,
				title: data.title,
				content: data.content,
				publishDate: data.publishDate,
				allowComments: data.allowComments,
				description: data.description
			};

			const response = await Core.Api.post('school/products-lesson-save', finalData);
			if (response.body.type === 'SUCCESS') {
				await this.fetchDetails(data.id, true);
				return true;
			}
			return false;
		},

		/**
		 * Create new lesson
		 * @memberof School_Store_Product
		 * @async
		 * @param {number} courseId if of the course we want new lesson for
		 * @returns {Promise<number|boolean>} if successful
		 */
		async createNewLesson(courseId) {
			const response = await Core.Api.post('school/products-lesson-create', { courseId });
			if (response.body.type === 'SUCCESS') {
				return response.body.message.id;
			} else {
				return false;
			}
		},

		// ------------------------- ACTIONS FOR ASSIGNMENTS -------------------------
		/**
		 * Create new assignment
		 * @memberof School_Store_Product
		 * @async
		 * @param {number} lessonId if of the course we want new lesson for
		 * @returns {Promise<number|boolean>} if successful
		 */
		async createNewAssignment(lessonId) {
			const response = await Core.Api.post('school/products-assignment-create', { lessonId });
			if (response.body.type === 'SUCCESS') {
				return response.body.message.id;
			} else {
				return false;
			}
		},

		/**
		 * Delete an assignment
		 * @memberof School_Store_Product
		 * @async
		 * @param {number} assignmentId of the assignment
		 * @param {number} lessonId of the lesson
		 * @returns {Promise<boolean>} if successful
		 */
		async deleteAssignment(assignmentId, lessonId) {
			const assignment = this.getAssignmentById(assignmentId, lessonId);

			if (assignment) {
				const response = await Core.Api.post('school/products-assignment-delete', {
					assignmentId: assignmentId
				});
				if (response.body.type === 'SUCCESS') {
					const lesson = this.getLessonById(lessonId);
					lesson.assignments = lesson.assignments.filter(
						(thisAssignment) => thisAssignment.id !== assignmentId
					);

					Core.Event.trigger('NOTIFICATIONS', {
						type: 'success',
						content: `Your assignment has been deleted!`,
						icon: 'iconFont-tick'
					});
					return true;
				}
			}

			Core.Event.trigger('NOTIFICATIONS', {
				type: 'danger',
				content: `Sorry, we couldn't delete this assignment. Try again later.`,
				icon: 'iconFont-cross'
			});
			return false;
		},

		/**
		 * Save assignment data
		 * @param {object} data to save
		 * @returns {Promise<boolean>} if successful
		 */
		async saveAssignment(data) {
			const response = await Core.Api.post('school/products-assignment-save', data);

			if (response.body.type === 'SUCCESS') {
				const lesson = this.getLessonById(data.lessonId);
				await this.fetchDetails(lesson.productId, true);
				return true;
			}
			return false;
		},

		// ------------------------- ACTIONS FOR PRODUCTS -------------------------
		/**
		 * Unpublish any given product
		 * @memberof School_Store_Product
		 * @async
		 * @param {number} productId of the product
		 * @returns {Promise<boolean>} once completed
		 */
		async unpublish(productId) {
			const product = this.getProductById(productId);

			if (product) {
				const response = await Core.Api.post('school/products-unpublish', {
					pageId: productId
				});

				if (response.body.type === 'SUCCESS') {
					product.publishDate = null;
					Core.Event.trigger('NOTIFICATIONS', {
						type: 'success',
						content: `Your ${product.type.toLowerCase()} has been removed form the public view and won\'t be listed on any page!`,
						icon: 'iconFont-tick'
					});
					return true;
				}
			}

			Core.Event.trigger('NOTIFICATIONS', {
				type: 'danger',
				content: `Sorry, we couldn't unpublish this ${product.type.toLowerCase()}. Try again later.`,
				icon: 'iconFont-cross'
			});
			return false;
		},

		/**
		 * Publish existing product
		 * @memberof School_Store_Product
		 * @async
		 * @param {number} productId of the product
		 * @returns {Promise<boolean>} if successful
		 */
		async publish(productId) {
			const product = this.getProductById(productId);

			if (product) {
				const response = await Core.Api.post('school/products-publish', {
					pageId: productId
				});
				if (response.body.type === 'SUCCESS') {
					product.publishDate = Core.Utils.createDate();
					Core.Event.trigger('NOTIFICATIONS', {
						type: 'success',
						content: `Your ${product.type.toLowerCase()} is now published!`,
						icon: 'iconFont-tick'
					});
					return true;
				}
			}

			Core.Event.trigger('NOTIFICATIONS', {
				type: 'danger',
				content: `Can't publish ${product.type.toLowerCase()}, please try again later.`,
				icon: 'iconFont-cross'
			});
			return false;
		},

		/**
		 * Delete existing product
		 * @memberof School_Store_Product
		 * @async
		 * @param {number} productId of the product
		 * @returns {Promise<boolean>} if successful
		 */
		async delete(productId) {
			const product = this.getProductById(productId);

			if (product) {
				const response = await Core.Api.post('school/products-delete', {
					productId: productId
				});
				if (response.body.type === 'SUCCESS') {
					this.data.products = this.data.products.filter(
						(thisProduct) => thisProduct.id !== productId
					);
					Core.Event.trigger('NOTIFICATIONS', {
						type: 'success',
						content: `Your ${product.type.toLowerCase()} has been deleted!`,
						icon: 'iconFont-tick'
					});
					return true;
				}
			}

			Core.Event.trigger('NOTIFICATIONS', {
				type: 'danger',
				content: `Sorry, we couldn't delete this ${product.type.toLowerCase()}. Try again later.`,
				icon: 'iconFont-cross'
			});
			return false;
		},

		/**
		 * Save product data
		 * @memberof School_Store_Product
		 * @async
		 * @param {object} data to save
		 * @returns {Promise<boolean>} if successful
		 */
		async save(data) {
			const finalData = {
				id: data.id,
				title: data.title,
				slug: data.slug,
				image: data.image,
				hideTitle: data.hideTitle,
				content: data.content,
				publishDate: data.publishDate,
				categories: data.categories,
				description: data.description,
				schoolId: data.schoolId,
				showSimilarContent: data.showSimilarContent,
				displayOrder: data.displayOrder,
				price: data.price || 0,
				author: data.author,
				meta: data.meta
			};

			const response = await Core.Api.post('school/products-save', finalData);
			if (response.body.type === 'SUCCESS') {
				await this.fetchDetails(data.id, true);
				return true;
			}
			return false;
		},

		/**
		 * Create new products
		 * @memberof School_Store_Product
		 * @async
		 * @param {string} type of the product you want to create (COURSE, DOWNLOAD, ARTICLE)
		 * @returns {Promise<number|boolean>} if successful
		 */
		async createNew(type) {
			const response = await Core.Api.post('school/products-create', { type });
			if (response.body.type === 'SUCCESS') {
				return response.body.message.id;
			} else {
				return false;
			}
		},

		/**
		 * Update order of products after user reorders them
		 * @memberof School_Store_Product
		 * @param {Array<object>} products objects with ID and displayOrder
		 */
		updateOrder(products) {
			Core.Api.post('school/products-update-order', {
				products: products.map((product) => ({
					id: product.id,
					displayOrder: product.displayOrder
				}))
			});
		}
	},

	getters: {
		/**
		 * Return all products
		 * @memberof School_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} data with all products
		 */
		getProducts: (state) => {
			return state.data.products;
		},

		/**
		 * Return all courses
		 * @memberof School_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} data with all courses
		 */
		getCourses: (state) => {
			return state.data.products
				.filter((product) => product.type === 'COURSE')
				.sort((a, b) => a.displayOrder - b.displayOrder);
		},

		/**
		 * Return all downloads
		 * @memberof School_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} data with all digital downloads
		 */
		getDownloads: (state) => {
			return state.data.products
				.filter((product) => product.type === 'DOWNLOAD')
				.sort((a, b) => a.displayOrder - b.displayOrder);
		},

		/**
		 * Return all articles
		 * @memberof School_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} data with all digital articles
		 */
		getArticles: (state) => {
			return state.data.products
				.filter((product) => product.type === 'ARTICLE')
				.sort((a, b) => a.displayOrder - b.displayOrder);
		},

		/**
		 * Return one product based on ID and type
		 * @memberof School_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Function} function asking for ID to get product details
		 */
		getProductById: (state) => {
			return (id) => {
				return state.data.products?.find((product) => product?.id === id) || null;
			};
		},

		/**
		 * Return one lesson based on ID
		 * @memberof School_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Function} function asking for ID to get lesson details
		 */
		getLessonById: (state) => {
			return (id) => {
				for (const product of state.data.products) {
					const lesson = product.lessons?.find(
						(thisLesson) => thisLesson.id === parseInt(id)
					);
					if (lesson) return lesson;
				}
			};
		},

		/**
		 * Return one assignment based on ID
		 * @memberof School_Store_Product
		 * @returns {Function} function, provide assignment ID and lesson ID
		 */
		getAssignmentById: function () {
			return function (assignmentId, lessonId) {
				const lesson = this.getLessonById(lessonId);
				if (!lesson) return null;
				return lesson.assignments?.find(
					(assignment) => assignment.id === parseInt(assignmentId)
				);
			};
		},

		/**
		 * Check if product is published
		 * @memberof School_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Function} expecting product ID
		 */
		isPublished: (state) => (id) => {
			const product = state.data.products.find((productItem) => productItem.id === id);
			if (!product?.publishDate) return false;
			return new Date(product.publishDate).getTime() < new Date().getTime();
		},

		/**
		 * Check if lesson is published
		 * @memberof School_Store_Product
		 * @returns {Function} expecting lesson ID
		 */
		isLessonPublished: function () {
			return function (id) {
				const lesson = this.getLessonById(id);
				if (!lesson?.publishDate) return false;
				if (lesson) return new Date(lesson.publishDate).getTime() < new Date().getTime();
			};
		},

		/**
		 * Gets all authors
		 * @memberof School_Store_Product
		 * @param {object} state automatically passed in
		 * @returns {Array} returns all authors from all products
		 */
		getAllAuthors: (state) => {
			const allAuthors = [];

			for (let i = 0; i < state.data.products.length; i++) {
				if (state.data.products[i].author) {
					allAuthors.push(state.data.products[i].author);
				}
			}

			// return unique records
			return [...new Set(allAuthors)];
		}
	}
});
