import * as Core from '@Core/index.js';

const DEFAULT_STORE = {
	data: {
		user: {}
	},
	loaded: {
		user: false
	}
};

// STORE DEFINITIONS -------------------
/**
 * @exports School_Store_User
 * @namespace School_Store_User
 */
export const useUserStore = Core.Pinia.defineStore({
	id: 'user',

	state: () => ({
		...Core.Utils.cloneObject(DEFAULT_STORE)
	}),

	actions: {
		/**
		 * Check if user is logged in, and get the details into store
		 * @async
		 * @param {boolean} force the check again, even it was done?
		 * @memberof School_Store_User
		 */
		async fetch(force) {
			if (!this.loaded.user || force) {
				const resetStore = Core.Utils.cloneObject(DEFAULT_STORE);
				this.data.user = { ...resetStore.data.user };
				this.loaded = { ...resetStore.loaded };

				const { body } = await Core.Api.get('school/user');

				if (body.type === 'SUCCESS') {
					this.data.user = body.message;
					this.loaded.user = true;
				}
			}
		},

		/**
		 * Logout the user
		 * @async
		 * @memberof School_Store_User
		 * @returns {Promise<boolean>} status type from the API
		 */
		async signOut() {
			const { body } = await Core.Api.get('school/user-signout/');
			if (body.status === 200) {
				this.reset();
			}
			return true;
		},

		/**
		 * Update user data
		 * @param {object} data for the user
		 * @memberof School_Store_User
		 * @returns {boolean} if the update was successful
		 */
		async update(data) {
			const response = await Core.Api.post('school/user-update', data);
			if (response.body.type === 'SUCCESS') {
				this.data.user = response.body.message;
				this.fetch(true);
				return true;
			}
			return false;
		},

		/**
		 * Reset the store to default values
		 * @memberof School_Store_User
		 */
		reset() {
			const resetStore = Core.Utils.cloneObject(DEFAULT_STORE);
			this.data = { ...resetStore.data };
			this.loaded = { ...resetStore.loaded };
		}
	},

	getters: {
		/**
		 * Return details about current user
		 * @memberof School_Store_User
		 * @param {object} state automatically passed in
		 * @returns {Array} data for design changes
		 */
		getUser: (state) => {
			return state.data.user;
		}
	}
});
