import * as Core from '@Core/index.js';

// STORE DEFINITIONS -------------------
/**
 * @exports School_Store_Other
 * @namespace School_Store_Other
 */
export const useOtherStore = Core.Pinia.defineStore({
	id: 'other',

	state: () => ({
		data: {
			sales: [],
			payouts: [],
			coupons: []
		},
		loaded: {
			sales: false,
			payouts: false,
			coupons: false
		}
	}),

	actions: {
		/**
		 * Get sales information for the current school
		 * @async
		 * @param {boolean} force the download again
		 * @memberof School_Store_Other
		 */
		async fetchSales(force) {
			if (!this.loaded.sales || force) {
				const response = await Core.Api.get('school/sales');
				this.data.sales = response.body.message;
				this.loaded.sales = true;
			}
		},

		/**
		 * Fetch data for payouts for current school
		 * @async
		 * @param {boolean} force the download again
		 * @memberof School_Store_Other
		 */
		async fetchPayouts(force) {
			if (!this.loaded.payouts || force) {
				const response = await Core.Api.get('school/payouts');
				this.data.payouts = response.body.message;
				this.loaded.payouts = true;
			}
		},

		/**
		 * Fetch data for coupons for current school
		 * @param {boolean} force the download again
		 * @memberof School_Store_Other
		 */
		async fetchCoupons(force) {
			if (!this.loaded.coupons || force) {
				const response = await Core.Api.get('school/coupons');
				this.data.coupons = response.body.message;
				this.loaded.coupons = true;
			}
		}
	},

	getters: {
		/**
		 * Return all sales data
		 * @memberof School_Store_Other
		 * @param {object} state automatically passed in
		 * @returns {Array} data for sales
		 */
		getSales: (state) => {
			return state.data.sales;
		},

		/**
		 * Return all payouts data
		 * @memberof School_Store_Other
		 * @param {object} state automatically passed in
		 * @returns {Array} data for payouts
		 */
		getPayouts: (state) => {
			return state.data.payouts;
		},

		/**
		 * Return all coupon data
		 * @memberof School_Store_Other
		 * @param {object} state automatically passed in
		 * @returns {Array} data for coupons
		 */
		getActiveCoupons: (state) => {
			return state.data.coupons.filter(
				(coupon) =>
					coupon.active &&
					(coupon.usedTimes < coupon.redemptionsLimit || coupon.redemptionsLimit === 0) &&
					(coupon.expiryDate === null || new Date(coupon.expiryDate) > new Date())
			);
		},

		/**
		 * Return all coupon data
		 * @memberof School_Store_Other
		 * @param {object} state automatically passed in
		 * @returns {Array} data for coupons
		 */
		getInactiveCoupons: (state) => {
			return state.data.coupons.filter(
				(coupon) =>
					!coupon.active ||
					(coupon.usedTimes >= coupon.redemptionsLimit &&
						coupon.redemptionsLimit !== 0) ||
					(coupon.expiryDate !== null && new Date(coupon.expiryDate) <= new Date())
			);
		}
	}
});
