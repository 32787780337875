import * as Core from '@Core/index.js';

// STORE DEFINITIONS -------------------
/**
 * @exports School_Store_Page
 * @namespace School_Store_Page
 */
export const usePageStore = Core.Pinia.defineStore({
	id: 'page',

	state: () => ({
		data: {
			pages: []
		},
		loaded: {
			pages: false
		}
	}),

	actions: {
		/**
		 * Fetch data for pages for current school
		 * @async
		 * @param {boolean} [force] the download again
		 * @memberof School_Store_Page
		 */
		async fetchPages(force) {
			if (!this.loaded.pages || force) {
				const response = await Core.Api.get('school/pages');
				this.data.pages = response.body.message;
				this.loaded.pages = true;
			}
		},

		/**
		 * Fetch data for single page
		 * @param {number} id of the page
		 * @param {boolean} [force] the download again
		 * @returns {Promise<boolean>} if successful
		 */
		async fetchDetails(id, force) {
			const pageIndex = this.data.pages.findIndex((page) => page.id === id);

			if (!pageIndex || force) {
				const response = await Core.Api.get(`school/pages-details/${id}`);

				if (pageIndex > -1) {
					this.data.pages[pageIndex] = response.body.message;
				} else {
					this.data.pages.push(response.body.message);
				}
			}

			return true;
		},

		/**
		 * Publish existing page
		 * @param {number} id of the page
		 * @returns {Promise<boolean>} if successful
		 */
		async publish(id) {
			const page = this.getDetails(id);

			if (page) {
				const response = await Core.Api.post('school/pages-publish', { pageId: page.id });
				if (response.body.type === 'SUCCESS') {
					page.publishDate = Core.Utils.createDate();
					Core.Event.trigger('NOTIFICATIONS', {
						type: 'success',
						content: 'Your page is now published!',
						icon: 'iconFont-tick'
					});
					return true;
				}
			}

			Core.Event.trigger('NOTIFICATIONS', {
				type: 'danger',
				content: "Can't publish page, please try again later.",
				icon: 'iconFont-cross'
			});
			return false;
		},

		/**
		 * Unpublish existing page
		 * @param {number} id of the page
		 * @returns {Promise<boolean>} if successful
		 */
		async unpublish(id) {
			const page = this.getDetails(id);

			if (page) {
				const response = await Core.Api.post('school/pages-unpublish', { pageId: page.id });
				if (response.body.type === 'SUCCESS') {
					page.publishDate = null;
					Core.Event.trigger('NOTIFICATIONS', {
						type: 'success',
						content: 'Your page has been removed form the public view!',
						icon: 'iconFont-tick'
					});
					return true;
				}
			}

			Core.Event.trigger('NOTIFICATIONS', {
				type: 'danger',
				content: "Sorry, we couldn't unpublish this page. Try again later.",
				icon: 'iconFont-cross'
			});
			return false;
		},

		/**
		 * Delete existing page
		 * @param {number} id of the page
		 * @returns {Promise<boolean>} if successful
		 */
		async delete(id) {
			const page = this.getDetails(id);

			if (page) {
				const response = await Core.Api.post('school/pages-delete', { pageId: page.id });
				if (response.body.type === 'SUCCESS') {
					this.data.pages = this.data.pages.filter((thisPage) => thisPage.id !== id);
					Core.Event.trigger('NOTIFICATIONS', {
						type: 'success',
						content: 'Your page has been deleted!',
						icon: 'iconFont-tick'
					});
					return true;
				}
			}

			Core.Event.trigger('NOTIFICATIONS', {
				type: 'danger',
				content: "Sorry, we couldn't delete this page. Try again later.",
				icon: 'iconFont-cross'
			});
			return false;
		},

		/**
		 * Save page data
		 * @param {object} data to save
		 * @returns {Promise<boolean>} if successful
		 */
		async save(data) {
			const response = await Core.Api.post('school/pages-save', data);
			if (response.body.type === 'SUCCESS') {
				this.fetchDetails(data.id, true);
				return true;
			}
			return false;
		},

		/**
		 * Create new page
		 * @returns {Promise<number|boolean>} if successful
		 */
		async createNew() {
			const response = await Core.Api.get('school/pages-create');
			if (response.body.type === 'SUCCESS') {
				return response.body.message.id;
			} else {
				return false;
			}
		}
	},

	getters: {
		/**
		 * Return all pages data
		 * @memberof School_Store_Page
		 * @param {object} state automatically passed in
		 * @returns {Array} data for pages
		 */
		getPages: (state) => {
			return state.data.pages;
		},

		/**
		 * All data for single page
		 * @memberof School_Store_Page
		 * @param {object} state automatically passed in
		 * @returns {object} object for one page
		 */
		getDetails: (state) => (id) => {
			return state.data.pages.find((thisPage) => thisPage?.id === id) || null;
		},

		/**
		 * Check if page is published
		 * @param {object} state automatically passed in
		 * @returns {Function} expecting page ID
		 */
		isPublished: (state) => (id) => {
			const page = state.data.pages?.find((thisPage) => thisPage?.id === id);
			if (!page?.publishDate) return false;
			return new Date(page.publishDate).getTime() < new Date().getTime();
		}
	}
});
