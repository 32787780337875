/**
 @module Student/Initialize
 */

import * as Core from '@Core/index.js';
import * as Store from '@Student/store/index.js';

let initialDataPromise;

/* ---------------------------------- PUBLIC METHODS ------------------------------------------ */
/**
 * It will pull all the initial data, in the right order needed for the user, business and products
 * @param {boolean} [force] new initialise data and overwrite previous promise.
 * @param {object} [router] the router object to use for navigation
 * @returns {Promise} true once resolved. No data
 */
export function start(force, router) {
	// if promise is already in progress, return it
	if (initialDataPromise && !force) {
		return initialDataPromise;
	}

	// if not, start the new initialise promise stuff
	initialDataPromise = new Promise((resolve) => {
		Store.school
			.init()
			.then(async (response) => {
				if (!response) {
					Store.language.update('en');
					router.push('/404#type=school-not-found');
					resolve();
					return;
				}

				Store.language.update(Store.school.getDetails.language); // set language based on school settings

				// check if user is coming back from google login
				if (Core.Hash.get('checkGoogle') === 'success') {
					await Core.Api.get('student/google-success');
					setTimeout(() => {
						Core.Hash.set('checkGoogle', null);
					}, 1000);
				}

				// check if user is logged in, pull details if yes
				const userStatus = await Core.Api.get('student/user-status/');
				if (userStatus.body.type === 'LOGGED_IN') {
					await Store.user.fetchUser();
				}

				// get all extra data needed for the system to work "now"
				await Store.product.fetchProducts();
				if (
					Store.product.getDownloads.length === 0 &&
					Store.product.getCourses.length === 0 &&
					Store.product.getArticles.length === 0 &&
					!response.homepage?.content
				) {
					router.push('/404#type=no-products');
					resolve();
				}

				// set favIcon
				Core.Utils.setFavicon(
					`${Core.Config.BUCKET_SCHOOL}/${Store.school.getDetails.id}/${Store.school.getDetails.icon}`
				);

				resolve();
			})
			.catch(() => {
				Store.language.update(); // set default lang
				// if (router.currentRoute.path !== '/error') {
				// 	router.push('/error');
				// }
				resolve();
			});
	});

	return initialDataPromise;
}

/**
 * Do all the initialise stuff when user logs in our out from the system.
 * This will ensure all the right things have been loaded at the right order etc, without making mess in the system.
 * This is always "forded" to make sure we always get the right data.
 */
export async function reset() {
	const userStatus = await Core.Api.get('student/user-status/');
	if (userStatus.body.type === 'LOGGED_IN') {
		await Store.user.fetchUser();
	} else {
		Store.user.reset();
	}
	await Store.product.fetchProducts(true);
}
