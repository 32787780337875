<template>
	<span
		class="BpPill"
		data-testid="blueprint-pill"
		:class="currentClasses"
		:data-tooltip="tooltip"
		><span
			v-if="icon"
			class="BpPill--icon"
			data-testid="blueprint-pill-icon"
			:class="'iconFont-' + icon"></span
		>{{ title }}</span
	>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';

	export default {
		name: 'BlueprintPill',

		//  ---------- PROPS ----------
		props: {
			/**
			 * property {string | number} title - to display for the pill
			 * @namespace Core_Blueprint_Pill
			 * @property {string | number} title - to display for the pill
			 */
			title: {
				type: [String, Number],
				required: true
			},
			/**
			 * property {string} [tooltip] - set tooltip for the pill title
			 * @namespace Core_Blueprint_Pill
			 * @property {string} [tooltip] - set tooltip for the pill title
			 */
			tooltip: {
				type: String,
				required: false,
				default: null
			},
			/**
			 * property {string} [icon] - class to be added before text
			 * @namespace Core_Blueprint_Pill
			 * @property {string} [icon] - class to be added before text
			 */
			icon: {
				type: String,
				required: false,
				default: null
			},
			/**
			 * property {string} [color] - set color to info | warning | danger | success | primary | secondary | tertiary
			 * @namespace Core_Blueprint_Pill
			 * @property {string} [color] - set color to info | warning | danger | success | primary | secondary | tertiary
			 */
			color: {
				type: String,
				required: false,
				default: null
			}
		},

		//  ---------- SETUP ----------
		setup(props) {
			/**
			 * render final classes for the current Pill component
			 */
			const currentClasses = Core.Vue.computed(() => {
				let finalClasses = '';
				props.tooltip ? (finalClasses += 'Tooltip Tooltip-bottom Tooltip-noUnderline') : '';

				props.color ? (finalClasses += ` BpPill-${props.color}`) : '';

				return finalClasses;
			});

			return { currentClasses };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpPill') {
		background-color: var(--color-greyStart);
		display: inline-flex;
		align-items: center;
		font-size: var(--text-tiny);
		padding: 5px 10px;
		margin-right: 10px;
		margin-bottom: 5px;
		margin-top: 5px;
		border-radius: var(--radius-regular);

		@include modifier('info') {
			background-color: $color-stateInfoBg;
			outline: 1.1px solid $color-stateInfo;
		}
		@include modifier('warning') {
			background-color: $color-stateWarningBg;
			outline: 1px solid $color-stateWarning;
		}
		@include modifier('danger') {
			background-color: $color-stateDangerBg;
			outline: 1px solid $color-stateDanger;
		}
		@include modifier('success') {
			background-color: $color-stateSuccessBg;
			outline: 1px solid $color-stateSuccess;
		}
		@include modifier('grey') {
			background-color: $color-grey900;
			outline: 1px solid $color-grey600;
		}
		@include modifier('primary') {
			background-color: $color-primary;
			outline: 1px solid $color-primary;
			color: var(--color-greyStart);
		}
		@include modifier('secondary') {
			background-color: $color-secondary;
			outline: 1px solid $color-secondary;
			color: var(--color-greyStart);
		}

		@include element('icon') {
			font-size: var(--text-tiny);
			margin-right: 5px;
		}
	}
</style>
