// IMPORTANT NOTES
// Worth reviewing in the near future as currently we assume all stores are used globally. It makes sense since they data-related, not feature or component based.
// Components REALLY should not have a reason to create their own store, but I might be wrong.
// If we need components-based store, it should likely live with the component (folder), not here, thus it will have different initialisation process (classic Pinia useXXXStore() assigned to a var)

import { useLanguageStore } from './language.js';
import { useUserStore } from './user.js';
import { useSchoolStore } from './school.js';
import { useProductStore } from './product.js';
import { useOtherStore } from './other.js';
import { useCommentStore } from './comment.js';
import { usePageStore } from './page.js';

// define dummy objects, as we need to export "something"
let language = {};
let user = {};
let school = {};
let product = {};
let other = {};
let comment = {};
let page = {};

/**
 * init function for our storage to initialise all storage Pinia storage.
 * I had to do it through init, which is fired just after we create Core.VueApp, but right before we actually mount the Vue app in index.js.
 * Timing here is actually important.
 * @namespace Student_Store
 */
function init() {
	language = useLanguageStore();
	user = useUserStore();
	school = useSchoolStore();
	product = useProductStore();
	other = useOtherStore();
	comment = useCommentStore();
	page = usePageStore();
}

export { init, language, user, school, product, other, comment, page };
