import * as Core from '@Core/index.js';

// STORE DEFINITIONS -------------------
/**
 * @exports School_Store_Comment
 * @namespace School_Store_Comment
 */
export const useCommentStore = Core.Pinia.defineStore({
	id: 'comment',

	state: () => ({
		data: {
			new: [],
			all: [],
			lessonComments: {},
			productComments: {}
		},
		loaded: {
			new: false,
			all: false
		}
	}),

	actions: {
		/**
		 * Fetch comments for the current school
		 * @async
		 * @param {boolean} onlyNew fetch only new
		 * @param {boolean} force the check again, even it was done?
		 * @memberof School_Store_Comment
		 */
		async fetch(onlyNew = false, force) {
			const key = onlyNew ? 'new' : 'all';

			if (!this.loaded[key] || force) {
				const response = await Core.Api.post('school/comments', { new: onlyNew });
				const newComments = response.body.message;
				this.data[key] = newComments;
				this.loaded[key] = true;
			}
		},

		/**
		 * Fetch comments for a specific lesson
		 * @async
		 * @param {number} lessonId the lesson ID
		 * @param {boolean} force the check again, even it was done?
		 * @memberof School_Store_Comment
		 */
		async fetchLessonComments(lessonId, force) {
			if (!this.data.lessonComments[lessonId] || force) {
				const response = await Core.Api.get(`school/comments-lesson/${lessonId}`);
				this.data.lessonComments[lessonId] = response.body.message;
			}
		},

		/**
		 * Fetch comments for a specific product
		 * @param {number} productId  the product ID
		 * @param {boolean} force the check again, even it was done?
		 * @memberof School_Store_Comment
		 */
		async fetchProductComments(productId, force) {
			if (!this.data.productComments[productId] || force) {
				const response = await Core.Api.get(`school/comments-product/${productId}`);
				this.data.productComments[productId] = response.body.message;
			}
		},

		/**
		 * Clean lessons comments since likely something changed (like read date for teacher)
		 * @memberof School_Store_Comment
		 */
		cleanLessonComments() {
			this.data.lessonComments = {};
		},

		/**
		 * Add reply by a teacjher to a comment
		 * @param {number} commentId the comment ID
		 * @param {string} reply the reply content
		 * @param {number} id of the product or lesson
		 * @param {string} type the type of relevant content where comment is posted
		 * @memberof School_Store_Comment
		 * @returns {Promise} the response
		 */
		async addReply(commentId, reply, id, type) {
			const response = await Core.Api.post('school/comments-add-reply', {
				commentId: commentId,
				content: reply,
				productId: type === 'product' ? id : 0,
				lessonId: type === 'lesson' ? id : 0
			});

			if (response.body.type === 'SUCCESS') {
				Core.Event.trigger('NOTIFICATIONS', {
					type: 'success',
					content: 'Your comment reply has been added successfully.',
					icon: 'iconFont-tick'
				});
			} else {
				Core.Event.trigger('NOTIFICATIONS', {
					type: 'danger',
					content:
						'There was an error adding your comment reply. Please try again later.',
					icon: 'iconFont-cross'
				});
			}

			return response.body.type;
		}
	},

	getters: {
		/**
		 * Return all comments data
		 * @memberof School_Store_Comment
		 * @param {object} state automatically passed in
		 * @returns {Array} data for comments
		 */
		getNew: (state) => {
			return state.data.new;
		},

		/**
		 * Return all comments data
		 * @param {object} state automatically passed in
		 * @returns {Array} data for comments
		 */
		getAll: (state) => {
			return state.data.all;
		},

		/**
		 * Return comments for a specific lesson
		 * @param {object} state automatically passed in
		 * @returns {Function} expecting lesson ID
		 */
		getLessonComments: (state) => (lessonId) => {
			return state.data.lessonComments[lessonId] || [];
		},

		/**
		 * Return comments for a specific product
		 * @param {object} state automatically passed in
		 * @returns {Function} expecting product ID
		 */
		getProductComments: (state) => (productId) => {
			return state.data.productComments[productId] || [];
		}
	}
});
