<template>
	<div class="container DesignDefault">
		<div class="DesignDefault--wrapperRow">
			<slot></slot>
		</div>
	</div>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	/**
	 * Design template for our applications. Default screen without any columns or extra stuff. Just one, main container but with footer and header
	 * @vue-prop {*} slot:unnamed slot for your main content or component
	 * @namespace Core_Design_Full
	 */

	export default {
		name: 'DesignDefault'
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('DesignDefault') {
		height: 100vh;

		@include element('wrapperRow') {
			height: 100%;
			min-height: calc(100% - 125px);
		}
	}
</style>
