/**
 @module Core/Utils
 */

import * as Vue from 'vue';

/**
 * Vue utility to add call to a function in your translations string.
 * @param {object} ref - this is your ref'ed DOM object from VUE (ex. let vHome = Core.Vue.ref(null);)
 * @param {string} id - query selector to identify the bit you want to be clickable (ex. #openTerms or .openTerms), try to use IDs
 * @param {object} callback - your callback function in vue to do whatever you want after the click.
 */
export function stringClick(ref, id, callback) {
	try {
		ref.value.querySelector(id).onclick = function (event) {
			event.preventDefault();
			callback(event, id);
		};
	} catch (e) {
		window.app.log('CORE', `Utils.stringClick did not find object ${id}in given element`);
	}
}

/**
 * Vue helper to validate multiple fields (provide vue ref fields)
 * For each item in the validationFields object, check if the value is valid
 * @param {object} validationFields object with all fields references
 * @returns {boolean} result of the validation
 */
export function validateFields(validationFields) {
	let validationPassed = true;

	for (const fieldName in validationFields) {
		// if Ref doesn't exist, skip
		if (!validationFields[fieldName].value) continue;

		let fieldValidationPassed = true; // Declare inside the loop to avoid unsafe references

		// Check if the ref is an array (its automatically an array if you use v-for)
		if (Array.isArray(validationFields[fieldName].value)) {
			validationFields[fieldName].value.forEach((item) => {
				if (!item.validate()) {
					fieldValidationPassed = false;
				}
			});
		} else if (!validationFields[fieldName].value.validate()) {
			fieldValidationPassed = false;
		}

		validationPassed = validationPassed && fieldValidationPassed;
	}

	return validationPassed;
}

/**
 * Helper function to reset multiple fields (provide vue ref fields)
 * @param {object} fields validationFields object with all fields references
 */
export function resetFields(fields) {
	Vue.nextTick(() => {
		for (const item in fields) {
			if (Array.isArray(fields[item]?.value)) {
				fields[item]?.value?.forEach((field) => {
					field.reset();
				});
			} else {
				fields[item]?.value?.reset();
			}
		}
	});
}

/**
 * Format bytes as human-readable text.
 * @param {number} bytes Number of bytes.
 * @param {boolean} [si=false] True to use metric (SI) units, aka powers of 1000. False to use binary (IEC), aka powers of 1024.
 * @param {number} [dp=1] Number of decimal places to display.
 * @returns {string} Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
	const thresh = si ? 1000 : 1024;

	if (Math.abs(bytes) < thresh) {
		return `${bytes} B`;
	}

	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
	let u = -1;
	const r = 10 ** dp;

	do {
		bytes /= thresh;
		++u;
	} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

	return `${bytes.toFixed(dp)} ${units[u]}`;
}

/**
 * Throttle functions calls by time
 * @param {object} func - function you want to call
 * @param {number} timeFrame - time in miliseconds
 * @param {object} [content] - object you want to pass to the target function
 * @returns {Function} to fire which will throttle
 */
export function Throttle(func, timeFrame, content) {
	let lastTime = 0;

	return function () {
		const now = new Date();
		if (now - lastTime >= timeFrame) {
			func(content);
			lastTime = now;
		}
	};
}

/**
 * Debounce calls to a function only after X time provided (every call resets the counter)
 * @param {Function} fn - function you are calling
 * @param {number} delay miliseconds od delay you want to create
 * @returns {Function} to fire which will debounce
 */
export function Debounce(fn, delay) {
	let timer = null;

	return function () {
		const context = this,
			args = arguments;

		clearTimeout(timer);
		timer = setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
}

/**
 * Generate a random string of characters of a specified length
 * @param {number} length The length of the string to be generated
 * @returns {string} A random string of characters
 */
export function makeid(length) {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

/**
 * Process title to pick the right language and replace dynamic parts
 * @param {object} titleObject from the route settings
 * @param {object} route from the vue router
 * @param {object} storeData from the vuex store
 * @returns {string} final title
 */
export function pageTitle(titleObject, route, storeData) {
	let finalTitle = titleObject.title[storeData.language.getCurrentCode] || titleObject.title.en;

	if (finalTitle.includes('%productTitle%')) {
		finalTitle = finalTitle.replace(
			'%productTitle%',
			storeData.product.getProductBySlug(route.params.productSlug)?.title
		);
	}

	if (finalTitle.includes('%lessonTitle%')) {
		finalTitle = finalTitle.replace(
			'%lessonTitle%',
			storeData.product.getLesson(route.params.productSlug, parseInt(route.params.lessonId))
				?.title
		);
	}

	if (finalTitle.includes('%assignmentTitle%')) {
		finalTitle = finalTitle.replace(
			'%assignmentTitle%',
			storeData.product.getAssignment(
				route.params.productSlug,
				route.params.lessonId,
				route.params.assignmentId
			)?.title || ''
		);
	}

	return finalTitle;
}

/**
 * Generate UUID format string for whatever you need it for :)
 * @returns {string} UUID format string
 */
export function makeUuid() {
	return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
		(c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
	);
}

/**
 * Get query parameter based on provided key
 * use like Core.Utils.queryValue.KEY
 * @param {string} key from the query param
 * @returns {object} value of the query param
 */
export function queryValue(key) {
	return new URLSearchParams(window.location.search).get(key);
}

/**
 * It takes an object and a value, and returns the name of the key that has that value
 * Basically, "find in object" stuff
 * @param {object} source - The source object [{name:'', value:''}]
 * @param {string | number} value - the value of the option
 * @returns {string} The name of the item in the source object that has a value equal to the value passed in.
 */
export function getNameByValue(source, value) {
	for (const item in source) {
		if (source[item].value === value) {
			return source[item].name;
		}
	}

	return undefined;
}

/**
 * It takes an object and a name, and returns the value of the key that has that name
 * @param {object} source - The source object [{name:'', value:''}]
 * @param {string | number} name - the name of the option
 * @returns {string} The value of the item in the source object that has a name equal to the name passed in.
 */
export function getValueByName(source, name) {
	for (const item in source) {
		if (source[item].name === name) {
			return source[item].value;
		}
	}

	return undefined;
}

/**
 * It takes a timestamp and returns a human readable date and time (localised automatically, based on language settings for the user)
 * @param {string | Date} time - The time to format (should really come in ISO format)
 * @param {string} [country='en'] - The country code to use for the date format.
 * @param {boolean} [showTime=true] - Show time for the given date?
 * @param {"long" | "numeric" | "2-digit" | "short" | "narrow"} [monthLength='long'] - The length of the month to display. Can be 'long' or 'short'.
 * @param {boolean} [showDay=true] - Show day for the given date?
 * @returns {string} with the date and time in a human readable format.
 */
export function humanTime(
	time,
	country = 'en',
	showTime = true,
	monthLength = 'long',
	showDay = true
) {
	const date = new Date(time);
	const dateTimeFormat = new Intl.DateTimeFormat(country, {
		year: 'numeric',
		month: monthLength,
		day: showDay ? 'numeric' : undefined,
		hour: showTime ? 'numeric' : undefined,
		minute: showTime ? 'numeric' : undefined
	});

	try {
		return dateTimeFormat.format(date);
	} catch (error) {
		return '?';
	}
}

/**
 * Transform price from Number to actual, localised prices
 * It will try to match the price against product (based on company size), if not, it will just convert the number you gave
 * @param {string | number | null} price company size string or actual price
 * @param {string} currency code of the currency
 * @param {object} [options={}] options to be passed to toLocaleString()
 * @returns {string} converted price with currency or £0.00 if something is wrong
 */
export function displayPrice(price, currency, options = {}) {
	return (
		price?.toLocaleString('en-GB', {
			style: 'currency',
			currency: currency,
			minimumFractionDigits: 2,
			...options
		}) || '£0.00'
	);
}

/**
 * It takes an ISO date and returns a string in the yyyy/mm/dd format
 * @param {string} isoDate - The ISO date to convert
 * @returns {string} in yyyy/mm/dd format
 */
export function isoToYearMonthDay(isoDate) {
	return isoDate.substring(0, 10);
}

/**
 * Formats relative time, for example "in 20 days" or "in 1 minute"
 * @param {number} value - value to format (not a date!)
 * @param {string} type -  "year", "quarter", "month", "week", "day", "hour", "minute", "second"
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/RelativeTimeFormat/format
 * @returns {string} - formatted string
 */
export function humanRelativeTime(value, type) {
	const relativeTimeFormatter = new Intl.RelativeTimeFormat('en');

	try {
		return relativeTimeFormatter.format(value, type);
	} catch (error) {
		return '?';
	}
}

/**
 * Clones Objects. Convert the input to JSON, then convert the JSON back to a JavaScript object.
 * @param {object} input - The object to be cloned.
 * @returns {object} A copy of the input object.
 */
export function cloneObject(input) {
	if (!input) return input;
	return JSON.parse(JSON.stringify(input));
}

/**
 * Dynamically load/inject JS script (external file?)
 * @param {string} url to load
 * @param {string} [id] to be set for the given script tag, so we can use it as a reference later
 * @param {boolean} [async=true] whenever script should be async
 * @returns {Promise} - resolves after script has been loaded and rejects on error
 */
export function injectScript(url, id = '', async = true) {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.onload = () => resolve(script);
		script.onerror = (error) => reject(error);
		script.async = async;
		script.src = url;
		script.id = id;

		document.head.appendChild(script);
	});
}

/**
 * It takes an id as an argument, and then scrolls to the element with that id
 * @param {string} id - The id of the element to scroll to.
 */
export function scrollToId(id) {
	document.querySelector(`#${id}`).scrollIntoView({
		behavior: 'smooth'
	});
}

/**
 * Get currency symbol based on currency code
 * @param {string} currency currency code
 * @returns {string} currency symbol
 * @example getCurrencySymbol('GBP') // £
 */
export function getCurrencySymbol(currency) {
	if (typeof currency !== 'string') {
		currency = 'GBP';
	}
	const formatter = new Intl.NumberFormat('en-GB', {
		style: 'currency',
		currency: currency || 'GBP'
	});
	return formatter.format(0).replace(/\d/g, '').replace(/\.$/, '').trim();
}

/**
 * Return date (dd-mm-yyyy) based on today date - / + number of days which then can be used in a form field, or something like that.
 * @param {number} [daysOffset] number of days to offset, can be negative
 * @returns {string} today's or modified date
 */
export function createDate(daysOffset) {
	const newDate = new Date();
	if (daysOffset) {
		newDate.setDate(newDate.getDate() + daysOffset);
	}

	const month = newDate.getMonth() + 1;
	const day = newDate.getDate();
	const year = newDate.getFullYear();

	return `${year}-${withLeadingZero(month)}-${withLeadingZero(day)}`;
}

/**
 * Deep merge two objects
 * @param {object} any provide objects as params which will be merged
 * @returns {object} new object after merge
 */
export function mergeDeep(...any) {
	// create a new object
	const target = {};

	// deep merge the object into the target object
	const merger = (obj) => {
		for (const prop in obj) {
			// eslint-disable-next-line no-prototype-builtins
			if (obj.hasOwnProperty(prop)) {
				if (Object.prototype.toString.call(obj[prop]) === '[object Object]') {
					// if the property is a nested object
					target[prop] = mergeDeep(target[prop], obj[prop]);
				} else {
					// for regular property
					target[prop] = obj[prop];
				}
			}
		}
	};

	// iterate through all objects and
	// deep merge them with target
	for (let i = 0; i < any.length; i++) {
		merger(any[i]);
	}

	return target;
}

/**
 * It returns 'Today' if the date is today, 'Yesterday' if the date is yesterday, and 'Older' if the
 * date is older than yesterday
 * @param {string} timestamp - The date you want to check.
 * @returns {boolean} the string 'Today' if the date is today, 'Yesterday' if the date is yesterday, and 'Older'
 * if the date is older than yesterday.
 */
export function getDateHistory(timestamp) {
	const date = new Date(timestamp);
	const today = new Date();
	const yesterday = new Date(today);
	yesterday.setDate(yesterday.getDate() - 1);

	return date.toDateString() === today.toDateString()
		? 'dateToday'
		: date.toDateString() === yesterday.toDateString()
			? 'dateYesterday'
			: 'dateOlder';
}

/**
 * Give me number of a month and I will give you month name localised based on date
 * @param {number} month provide number of the month (not index, January = 1)
 * @param {boolean} [long=true] when set to false it will return short name
 * @param {number} [offset=0] The offset from the current month. Positive values are future months, negative values are past months.
 * @returns {string} readable month name
 */
export function getMonthName(month, long = true, offset = 0) {
	const objDate = new Date();
	objDate.setDate(1);
	objDate.setMonth(month - 1 + offset);

	const locale = 'en-us';
	const monthString = objDate.toLocaleString(locale, { month: long ? 'long' : 'short' });

	return monthString;
}

/**
 * Formats number
 * @param {number} number - number to be formatted
 * @returns {string} - formatted number
 */
export function formatNumber(number) {
	return new Intl.NumberFormat('en-GB', {
		maximumFractionDigits: 1
	}).format(number);
}

/**
 * Deep compare two objects if they are the same
 * @param {object} object1 first object for comparison
 * @param {object} object2 second object for comparison
 * @returns {boolean} whether two object are same, or not
 */
export function compareObjects(object1, object2) {
	return JSON.stringify(object1) === JSON.stringify(object2);
}

/**
 * Adds leading zero to a number if needed. Useful for formatting months and days
 * @param {number} number - input number
 * @returns {string|number} a string with zero in front if needed, or parameter if it's not a valid number
 */
export function withLeadingZero(number) {
	if (typeof number !== 'number' || isNaN(number)) {
		return number; // Return input if it's not a valid number
	}

	return number < 10 ? `0${number}` : number.toString();
}

/**
 * Set meta tag in the head of the document
 * @param {string} name - name of the meta tag
 * @param {string} content - content of the meta tag
 */
export function setMetaTag(name, content) {
	// remove existing meta if it exists first
	const existingMeta = document.querySelector(`meta[property="${name}"]`);
	if (existingMeta) {
		existingMeta.remove();
	}

	// add new meta tag
	const meta = document.createElement('meta');
	meta.setAttribute('property', name);
	meta.content = content;
	document.head.appendChild(meta);
}

/**
 * Set favicon in the head of the document
 * @param {string} url - url of the file, without the domain and stuff
 */
export function setFavicon(url) {
	const favicon = document.querySelector('link[rel="icon"]') || document.createElement('link');
	favicon.rel = 'icon';
	favicon.href = url;
	document.head.appendChild(favicon);
}
