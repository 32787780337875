import * as Core from '@Core/index.js';
import App from './app.vue';
import router from './router/index.built.js';
import * as Store from './store/index.js';

// -------------------------------------- BOOSTRAP THE APP ---------------------------------
const VueApp = Core.Vue.createApp(App).use(Core.Pinia.createPinia()).use(router);

// initialise store and stuff
Core.init(VueApp); // initialise core, only this one time
Store.init();
Core.Tracking.init(Core.Config.GOOGLE_KEY_STUDENT);
VueApp.mount('#app');
