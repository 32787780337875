<template>
	<blueprint-popup
		ref="popup"
		:header="state.title"
		class="BpErrorHandler"
		data-testid="error-handler">
		<template #default>
			<div class="row">
				<div class="d-none d-sm-block col-sm-6 Text-center align-self-center">
					<div
						class="BpErrorHandler--icon"
						:class="state.icon"
						data-testid="errorHandler-icon"></div>
				</div>
				<div
					class="align-self-center"
					:class="state.icon ? 'col-24 col-sm-18' : 'col-24'">
					<p>{{ state.text }}</p>
				</div>

				<div class="col-24 d-sm-none"></div>
				<div class="col-24 d-sm-none">
					<div
						v-if="state.btnOk"
						class="Btn Btn-fullWidth"
						@click="$refs.popup.toggle(false)">
						{{ language.string.cErrorHandler?.btnOk }}
					</div>
				</div>

				<div class="d-none d-sm-block col-sm-12"></div>
				<div class="d-none d-sm-block col-sm-12 Text-right">
					<div
						v-if="state.btnOk"
						class="Btn Space-topDouble"
						@click="$refs.popup.toggle(false)">
						{{ language.string.cErrorHandler?.btnOk }}
					</div>
				</div>

				<div class="col-24">
					<div
						v-if="state.notes"
						class="BpErrorHandler--notes">
						<pre>{{ state.notes }}</pre>
					</div>
				</div>
			</div>
		</template>
	</blueprint-popup>
</template>

// -------------------------------------- SCRIPT ----------------------------------------------
<script>
	import * as Core from '@Core/index.js';
	import { useLanguageStore } from '@Core/store/language.js';

	export default {
		name: 'ErrorHandler',

		//  ---------- SETUP ----------
		/**
		 * Error Handler component cannot be used directly. It is embeeded within main templates and can be created/shown by Core.Event.trigger() method only.
		 * Check Events/ERROR section in the documention for details.
		 * Trigger data accept data in spefic format only.
		 * @see events/ERROR
		 * @namespace Core_ErrorHandler
		 * @returns {object} Component
		 */
		setup() {
			const language = useLanguageStore();
			const popup = Core.Vue.ref();

			const state = Core.Vue.ref({
				title: '',
				text: '',
				notes: '',
				icon: '',
				btnOk: true
			});

			/**
			 * Global Event.trigger() event name 'ERROR' to display Error popup and handle exceptions and problems.
			 * This is part of Core_ErrorHandler component. It requires object with extra data.
			 * @event ERROR
			 * @see Core_ErrorHandler
			 * @param {string} [type] - define type of error
			 * @param {string} [title] - title for the error popup (ignored when type matches)
			 * @param {string} [text] - main body text (not html) (ignored when type matches)
			 * @param {string} [notes] - small footer notes (ignored when type matches)
			 * @param {string} [icon] - icon to be displayed, as iconFont class (ignored when type matches)
			 * @param {boolean} [btnOk=true] - show OK button (ignored when type matches)
			 */
			Core.Vue.onBeforeMount(() => {
				Core.Event.on('ERROR', function (name, data) {
					if (data?.type && language.string.cErrorHandler?.[data.type]) {
						state.value = language.string.cErrorHandler?.[data.type];
						state.value.notes = data.notes;
					} else if (data?.type === 'custom') {
						state.value = data;
					} else {
						state.value = language.string.cErrorHandler?.['DEFAULT'];
					}

					if (state.value?.title) {
						popup.value?.toggle(true);
					}
				});
			});

			return { popup, state, language };
		}
	};
</script>

// -------------------------------------- STYLES ----------------------------------------------
<style lang="scss">
	@include block('BpErrorHandler') {
		@include element('notes') {
			border-top: 1px solid var(--color-grey700);
			color: var(--color-grey700);
			padding-top: var(--space-single);
			font-size: var(--text-tiny);
			margin-top: var(--space-single);
		}

		@include element('icon') {
			font-size: 100px !important;
			line-height: 100px;
			color: var(--color-grey500);
		}
	}
</style>
