<template>
	<div class="AiHelper">
		<blueprint-popup
			ref="aiPopup"
			size="col-24 col-md-20 offset-md-2"
			header="AI Creator"
			footer='<p class="Text-center"><b>Beta version.</b> This feature is still in a testing phase. Please provide feedback to help us improve.</p>'
			:on-close="cleanData">
			<!-- TOO SHORT CONTENT  -->
			<div
				v-if="state.tooShort"
				class="row">
				<p class="AiHelper--limitExceeded">
					<span class="iconFont-warning Color-warning"></span><br />
					The content you selected is too short.<br />Please select a longer portion of
					your content.
				</p>

				<div class="col-24 Text-center">
					<button
						class="Btn Btn-secondary"
						@click="close">
						Close
					</button>
				</div>
			</div>

			<!-- LIMIT EXCEEDED TOO LONG  -->
			<div
				v-else-if="state.limitExceeded"
				class="row">
				<p class="AiHelper--limitExceeded">
					<span class="iconFont-warning Color-danger"></span><br />

					You have exceeded the content limit of
					{{ CONTENT_LIMIT }} characters.<br />Please select a smaller portion of your
					content.
				</p>

				<div class="col-24 Text-center">
					<button
						class="Btn Btn-secondary"
						@click="close">
						Close
					</button>
				</div>
			</div>

			<!-- DAILY LIMIT REACHED  -->
			<div v-else-if="state.usedRequests >= state.dailyLimit">
				<div class="col-12 offset-6 Text-center">
					<div class="Callout Callout-danger Text-center">
						<p>
							You have reached the daily usage limit of
							{{ state.dailyLimit }} requests. <br />Please try again tomorrow.
						</p>
					</div>
					<button
						class="Btn Btn-secondary"
						@click="close">
						Close
					</button>
				</div>
			</div>

			<!-- MAIN CONTENT  -->
			<template v-else>
				<transition
					name="fade"
					mode="out-in">
					<div
						v-if="!state.results && !state.failedResponse"
						class="row align-items-center">
						<div class="col-2">
							<img src="@School/assets/ai-icon.png?url" />
						</div>
						<div class="col-14">
							<p>
								This tool is designed to enhance your writing, not replace it. Its
								goal is to assist you in refining and improving your content, rather
								than generating everything for you.
							</p>
						</div>
						<div class="col-8">
							<div
								class="Callout"
								:class="
									state.usedRequests >= state.dailyLimit
										? 'Callout-danger'
										: 'Callout-info'
								">
								<p class="Text-center">
									<b>Daily usage limit:</b> {{ state.usedRequests || 0 }} /
									{{ state.dailyLimit }} requests
								</p>
							</div>
						</div>

						<blueprint-select
							id="mode"
							:ref="fields.mode"
							v-model:value="state.mode"
							class="col-12"
							:options="modeOptions"
							label="What you want to do with your content?"
							required />

						<blueprint-select
							id="tone"
							:ref="fields.tone"
							v-model:value="state.tone"
							class="col-12"
							:options="toneOptions"
							label="Tone of your content"
							required />

						<div class="col-24">
							<div class="AiHelper--prompt StandardHtml">
								<div v-html="state.prompt"></div>
							</div>
						</div>

						<div class="col-24 Text-center">
							<button
								class="Btn Btn-secondary Space-right"
								@click="close">
								Cancel
							</button>
							<button
								class="Btn"
								:class="state.submitting && 'Btn-loading'"
								@click="submitPrompt">
								Get new content
							</button>
						</div>
					</div>

					<div
						v-else-if="state.failedResponse"
						class="col-18 offset-3 Text-center">
						<div class="Callout Callout-warning Text-center">
							<p>
								Sorry, but nothing was found to correct or improve in your content.
							</p>
						</div>
						<button
							class="Btn Btn-secondary Space-right"
							@click="close">
							Finish without changes
						</button>
					</div>

					<div
						v-else
						class="row">
						<div class="col-24">
							<div class="AiHelper--results StandardHtml">
								<div v-html="state.results"></div>
							</div>

							<div class="Text-center">
								<button
									class="Btn Btn-secondary Space-right"
									@click="state.results = ''">
									Try again
								</button>
								<button
									class="Btn"
									@click="acceptContent">
									Accept and continue
								</button>
							</div>
						</div>

						<div
							v-if="!state.feedbackOpen"
							class="col-24 Text-center Text-link Space-top Space-bottom">
							<p @click="state.feedbackOpen = true">
								Give us feedback on the AI tool
							</p>
						</div>

						<div
							v-else-if="state.feedbackSuccess"
							class="Text-h4 Color-success Text-center Space-topDouble">
							Thank you for the feedback!
						</div>
						<div
							v-else
							class="col-24 AiHelper--feedbackBox">
							<div class="row">
								<blueprint-input
									id="feedback"
									ref="feedbackField"
									v-model:value="state.feedback"
									:max-length="1000"
									class="col-12 offset-3"
									label="Tell us what you think"
									required />

								<div class="col-3">
									<div
										class="Btn Btn-secondary Btn-formAligned"
										@click="submitFeedback">
										Submit
									</div>
								</div>
							</div>
						</div>
					</div>
				</transition>
			</template>
		</blueprint-popup>
	</div>
</template>

<script>
	import * as Store from '@School/store/index.js';
	import * as Core from '@Core/index.js';

	const CONTENT_LIMIT = 5000;

	const DEFAULT_STATE = {
		mode: 'correction',
		tone: 'formal',
		relatedContent: null,
		submitting: false,
		results: '',
		limitExceeded: false,
		tooShort: false,
		failedResponse: false,
		dailyLimit: 20,
		usedRequests: 0,
		feedback: '',
		feedbackOpen: false,
		feedbackSuccess: false
	};

	export default {
		name: 'AiHelper',

		//  -------------------- PROPS --------------------
		props: {
			/**
			 * property {string} [content] - The content to be processed by the AI
			 * @namespace School_AiHelper
			 * @property {string} [content] - The content to be processed by the AI
			 */
			content: {
				type: String,
				required: false,
				default: ''
			},

			/**
			 * property {string} project - The project to be processed by the AI
			 * @namespace School_AiHelper
			 * @property {string} project - The project to be processed by the AI
			 */
			project: {
				type: String,
				validation: (value) => ['product', 'comment'].includes(value),
				required: true
			}
		},

		emits: ['update'],

		//  -------------------- SETUP --------------------
		setup(props, context) {
			const aiPopup = Core.Vue.ref();

			const state = Core.Vue.reactive({
				...DEFAULT_STATE,
				prompt: props.content
			});

			const fields = {
				mode: Core.Vue.ref(),
				tone: Core.Vue.ref(),
				prompt: Core.Vue.ref()
			};

			const feedbackField = Core.Vue.ref();

			const modeOptions = [
				{
					value: 'correction',
					name: 'Grammar and spelling errors'
				},
				{
					value: 'rewrite',
					name: 'Enhance language'
				},
				{
					value: 'rewriteAndRestructure',
					name: 'Improve language and structure'
				}
			];

			const toneOptions = [
				{ value: 'formal', name: 'Formal' },
				{ value: 'informal', name: 'Informal' },
				{ value: 'funny', name: 'Funny' }
			];

			// -------------------- WATCH --------------------
			// watch if prop content changes
			Core.Vue.watch(
				() => props.content,
				(value) => {
					state.prompt = value;
				}
			);

			// -------------------- METHODS --------------------
			/**
			 * Open the AI popup
			 * @param {string} content - The content to be processed by the AI
			 * @param {string} relatedContent - The related content to be processed by the AI (optional)
			 */
			async function open(content, relatedContent) {
				if (!content || content.length < 16) {
					state.tooShort = true;
				} else if (content.length > CONTENT_LIMIT) {
					state.limitExceeded = true;
				} else {
					state.prompt = content;
					state.relatedContent = relatedContent;
				}

				// get limit from the API
				const responseLimit = await Core.Api.get('school/ai-limit');
				state.usedRequests = responseLimit.body.message || 0;

				aiPopup.value.toggle();
			}

			/**
			 * Close the AI popup (and clean data)
			 */
			function close() {
				aiPopup.value.toggle();
				setTimeout(() => {
					cleanData();
				}, 300);
			}

			/**
			 * Submit the prompt to the AI and get the results
			 */
			async function submitPrompt() {
				state.submitting = true;

				if (Core.Utils.validateFields(fields)) {
					let response;

					// request for products (likely htm content)
					if (props.project === 'product') {
						response = await Core.Api.post('school/ai-product', {
							content: state.prompt,
							mode: state.mode,
							tone: state.tone
						});
					}

					if (props.project === 'comment') {
						response = await Core.Api.post('school/ai-comment', {
							content: state.prompt,
							relatedContent: state.relatedContent,
							mode: state.mode,
							tone: state.tone
						});
					}

					if (response.body.type === 'SUCCESS') {
						state.results = response.body.message;
					}

					if (response.body.type === 'CONFLICT') {
						state.failedResponse = true;
					}
				}
				state.submitting = false;
			}

			/**
			 * Accept the content and continue
			 */
			function cleanData() {
				for (const key in state) {
					state[key] = DEFAULT_STATE[key];
				}
			}

			/**
			 * Accept the content and continue
			 */
			function acceptContent() {
				aiPopup.value.toggle();
				context.emit('update', state.results);
				cleanData();
			}

			/**
			 * Submit feedback to the AI
			 */
			async function submitFeedback() {
				if (Core.Utils.validateFields([feedbackField])) {
					await Core.Api.post('school/ai-feedback', {
						content: state.feedback
					});

					state.feedback = '';
					state.feedbackSuccess = true;
				}
			}

			return {
				Store,
				Core,
				aiPopup,
				state,
				fields,
				feedbackField,
				submitPrompt,
				modeOptions,
				cleanData,
				toneOptions,
				acceptContent,
				open,
				close,
				submitFeedback
			};
		}
	};
</script>

<style lang="scss">
	@include block('AiHelper') {
		@include element('prompt') {
			margin-top: var(--space-singe);
			border: 1px solid var(--color-grey500);
			border-radius: var(--radius-big);
			padding: var(--space-single);
			background-color: var(--color-grey950);
			user-select: none;
		}

		@include element('results') {
			margin-top: var(--space-singe);
			border: 1px solid var(--color-grey500);
			border-radius: var(--radius-big);
			padding: var(--space-single);
		}

		@include element('limitExceeded') {
			font-size: var(--text-h4);
			text-align: center;
			margin-top: var(--space-double);

			& .iconFont-warning {
				font-size: var(--text-h1);
			}
		}

		@include element('feedbackBox') {
			margin-top: var(--space-single);
			padding-top: var(--space-single);
			padding-bottom: var(--space-single);
			background-color: var(--color-stateInfoBg);

			& .BpForms--input {
				background-color: var(--color-greyStart);
			}
		}
	}
</style>
